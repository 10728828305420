<template>
  <m-sidebar class="sidebar-component" gap="none" min-content-width="600px">
    <m-box slot="sidebar" id="sidebar" class="flex flex-column">
      <div class="full-height sidebar-content">
        <v-logo></v-logo>
        <div class="logo-tag hide-mobile">
          <m-text variant="heading-md" as="p" color="color-text-inverted">
            The industry's leading dock management and scheduling tool
          </m-text>
        </div>
      </div>

      <v-spacer class="hide-tablet"></v-spacer>
      <copyright-text class="hide-tablet" color="color-text-secondary-inverted"></copyright-text>
      <auth-links color="color-text-inverted" class="hide-tablet"></auth-links>
    </m-box>

    <m-box slot="content" id="main-content">
      <div class="pa-4 d-flex flex-row align-content-center new-sign-in-warning">
        <v-icon class="mr-4" size="24px">mdi-bell</v-icon>
        <div class="d-flex flex-column">
          <span class="font-weight-bold">Welcome to the new Opendock sign in page</span>
          <span>
            You can now use this page to sign in to both the Warehouse Portal and the Scheduling
            Portal, using the same credentials as before.
          </span>
        </div>
      </div>
      <template v-if="!emailVerificationData.isEmailVerified">
        <v-loader v-if="isLoading" :is-loading="isLoading">Sending verification email</v-loader>
        <m-stack
          v-else
          align="center"
          class="height-100 p-x-4 email-not-verified-container"
          justify="center">
          <m-stack gap="spacing-3" align="center">
            <v-icon size="88px" color="color-primary-60" class="p-b-2">email-check</v-icon>
            <m-text variant="heading-md-bold">Check your e-mail account</m-text>
            <m-text variant="body-md" as="p">
              Open the message we sent you and click the "Activate my account" button to start using
              Opendock
            </m-text>
          </m-stack>
          <m-stack gap="spacing-3" align="center" class="p-y-10">
            <m-text variant="body-md" as="p">
              Couldn't find our message? Check your spam folder or resend email
            </m-text>
            <tertiary-button
              leading-icon="refresh"
              test-id="resend-verification-btn"
              size="small"
              block
              @click="sendVerificationEmail"
              :loading="isLoading">
              RESEND EMAIL
            </tertiary-button>
          </m-stack>

          <m-stack gap="0" align="center" justify="center">
            <m-text variant="body-md">In case you're having any trouble, contact us on:</m-text>
            <m-link href="mailto:support@opendock.com">support@opendock.com</m-link>
          </m-stack>
        </m-stack>
      </template>

      <template v-else>
        <m-stack id="login-form-container" justify="center">
          <login-form
            :verification-token="verificationToken"
            :post-login-route="postLoginRoute"
            :is-manage-appointment-login="isManageAppointmentLogin"
            @update:emailVerificationData="setEmailVerificationData" />
          <m-group class="p-t-4">
            <m-text>
              Looking to sign up for our
              <strong>Scheduling Portal</strong>
              ?
            </m-text>
            <v-link href="/register" target="_blank">Register here</v-link>
          </m-group>
          <copyright-text class="hide-desktop"></copyright-text>
          <auth-links class="hide-desktop"></auth-links>
        </m-stack>

        <reset-password-dialog
          v-model="dialogs.resetPassword"
          @close="dialogs.resetPassword = false"
          :reset-token="resetToken"
          :email="resetEmail" />
      </template>
    </m-box>
  </m-sidebar>
</template>

<script>
import {
  VSpacer,
  AuthLinks,
  CopyrightText,
  VLogo,
  VLink,
  TertiaryButton,
  VIcon,
  VLoader
} from '@/components';
import LoginForm from '@/modules/auth/components/LoginForm.vue';
import ResetPasswordDialog from '@/components/elements/auth/dialogs/ResetPasswordDialog.vue';

export default {
  components: {
    VIcon,
    TertiaryButton,
    CopyrightText,
    AuthLinks,
    LoginForm,
    VSpacer,
    ResetPasswordDialog,
    VLogo,
    VLink,
    VLoader
  },
  props: {
    /**
     * Verification Token
     */
    verificationToken: {
      type: String,
      required: false
    },
    /**
     * Reset Email
     */
    resetEmail: {
      type: String,
      required: false
    },
    /**
     * Reset Token
     */
    resetToken: {
      type: String,
      required: false
    },
    /**
     * Route to go to once login is completed
     * The appointment ID will be in the params when coming from the appointment details page in public view
     * so we can send the user to the correct appointment after login
     * Prop drilling happening here since the LoginForm is wrapped by this component.  We don't want this in the store.
     */
    postLoginRoute: {
      type: Object,
      required: false
    },
    /**
     * Is this a login request from clicking the manage appointment button on a public appointment?
     * Prop drilling happening here since the LoginForm is wrapped by this component.  We don't want this in the store.
     */
    isManageAppointmentLogin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      emailVerificationData: {
        isEmailVerified: true,
        email: null
      },
      dialogs: {
        resetPassword: false
      }
    };
  },
  computed: {
    shouldShowResetDialog() {
      return Boolean(this.resetEmail) && Boolean(this.resetToken);
    }
  },
  methods: {
    async sendVerificationEmail() {
      this.isLoading = true;

      const response = await this.services.carrier
        .resendVerificationEmail({
          email: this.emailVerificationData.email
        })
        .finally(() => {
          this.isLoading = false;
        });
      if (response?.statusCode === 200) {
        this.notify(`Verification email sent to ${this.emailVerificationData.email}!`);
      }
    },
    setEmailVerificationData({ isEmailVerified, email }) {
      this.emailVerificationData.isEmailVerified = isEmailVerified;
      this.emailVerificationData.email = email;
    }
  },
  mounted() {
    this.dialogs.resetPassword = this.shouldShowResetDialog;
    this.mixpanel.track(this.mixpanel.events.VIEW.LOGIN_PAGE);
    if (this.verificationToken) {
      this.mixpanel.track(this.mixpanel.events.ACTION.ACTIVATE_ACCOUNT);
    }
  }
};
</script>

<style scoped lang="scss">
#sidebar {
  background-size: cover;
  background-image: url('@/../public/images/auth-sidebar.png');
  background-position: center;
  background-color: var(--primary-primary-100, #033);
  background-blend-mode: multiply;
  border-radius: 0;
  opacity: 0.85;
  width: 600px;
  padding: $m-spacing-8;

  @media (max-width: $midDesktopBreakpoint) {
    width: 424px;
    padding: $m-spacing-4;
  }

  @media (max-width: $smallDesktopBreakpoint) {
    height: 125px;
  }

  @media (max-width: $tabletBreakpoint) {
    margin-top: 100px;
  }

  .logo-tag {
    @media (max-width: $midDesktopBreakpoint) {
      width: 400px;
    }
    @media (max-width: $smallDesktopBreakpoint) {
      width: 100%;
    }
  }

  .sidebar-content {
    width: 395px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $m-spacing-4;
    justify-content: center;

    @media (max-width: $smallDesktopBreakpoint) {
      flex-direction: row;
      width: 90%;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: $tabletBreakpoint) {
      width: 100%;
      //gap: 0 !important;
    }
  }
}
.sidebar-component {
  height: 100%;
  @media (max-width: $smallDesktopBreakpoint) {
    height: auto;
  }
}
.new-sign-in-warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fcfcff;
}
#main-content {
  @media (max-width: $smallDesktopBreakpoint) {
    min-width: 100% !important;
  }
}
#login-form-container {
  width: 525px;
  margin: 0 auto;
  padding: $m-spacing-8;
  height: 100%;

  @media (max-width: 1200px) {
    padding: $m-spacing-4;
  }

  @media (max-width: $smallDesktopBreakpoint) {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}

.email-not-verified-container {
  max-width: 530px;
  margin: auto;
}

.hide-mobile {
  @media (max-width: $largeMobileBreakpoint) {
    display: none;
  }
}
.hide-tablet {
  @media (max-width: $smallDesktopBreakpoint) {
    display: none;
  }
}
.hide-desktop {
  @media (min-width: $smallDesktopBreakpoint) {
    display: none;
  }
}
</style>
